<div *ngIf="!isQueryLoaded" class="mx-auto">
  <span class="text-primary fa fa-spinner fa-spin fa-2x"></span>
</div>
<div *ngIf="isQueryLoaded === true" class="d-flex flex-row">
  <form [formGroup]="queryOptionForm" (ngSubmit)="saveQueryOption()">
    <div class="d-flex flex-row m-1">
      <div class="d-flex flex-column">
        <!-- Data Source -->
        <div class="input-group input-group-sm mb-2" style="float: left;">
          <div class="input-group-prepend">
            <span class="input-group-text" id="datasource-label" style="width: 6rem;">Data Source:</span>
          </div>
          <select class="custom-select-sm mr-3 required" id="dataSourceSelect" style="width: 15rem;"
            formControlName="dataSource" aria-label="Data Source">
            <option disabled hidden>Select DataSource</option>
            <option *ngFor="let source of dataSourceList" [ngValue]="source.id">{{ source.name }}</option>
          </select>
        </div>
        <!-- Query -->
        <div class="input-group input-group-sm" style="float: left;">
          <div class="input-group-prepend">
            <span class="input-group-text" id="query-label" style="width: 6rem;">Query:</span>
          </div>
          <textarea class="form-control form-control-xl required"
            [placeholder]="isImportedDataSource?'Query is not required for imported data source':''"
            formControlName="query" rows="3" aria-label="Query" style="width: 38rem; height: 58vh;"></textarea>
        </div>
      </div>
      <div class="d-flex flex-column ml-1 mr-1">
        <!-- Start Activities -->
        <div class="input-group input-group-sm mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text" id="start-activities-label" style="width: 11rem;">Start Activities:</span>
          </div>
          <input type="text" class="form-control form-control-md" formControlName="startEvents" maxlength="500"
            aria-label="Start Activities (Comma Separated)" style="width: 22rem;"
            placeholder="(comma separated values)">
        </div>
        <!-- End Activities -->
        <div class="input-group input-group-sm mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text" id="end-activities-label" style="width: 11rem;">End Activities:</span>
          </div>
          <input type="text" class="form-control form-control-md" formControlName="endEvents" maxlength="500"
            aria-label="End Activities (Comma Separated)" style="width: 22rem;" placeholder="(comma separated values)">
        </div>

        <!-- Prune Threshold -->
        <div class="input-group input-group-sm mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text" id="prune-threshold-label" style="width: 11rem;">Prune Threshold:</span>
          </div>
          <input type="text" class="form-control form-control-md required" formControlName="pruneThreshold"
            maxlength="10" aria-label="Prune Threshold" style="width: 22rem;">
        </div>
        <div *ngIf="queryOptionForm.controls.pruneThreshold.errors?.isPruneThresholdInvalid === true"
          style="height:1.8rem;">
          <div class="pt-1" role="alert" style="height: 1.8rem;color: red;">
            <small>{{ queryOptionForm.controls.pruneThreshold.errors?.pruneThresholdInvalidMessage }}</small>
          </div>
        </div>

        <!-- Include Active Cases -->
        <div class="d-flex flex-row">
          <div class="input-group input-group-sm mb-1" style="max-width: 15rem;">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="includeActiveCases">
              <label class="custom-control-label" style="color:#495057; font-size: 0.875rem" for="customSwitch1">Include Active Cases
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="queryOptionForm.errors?.isIncludeActiveCasesInvalid"
          style="height:1.8rem;">
          <div class="pt-1" role="alert" style="height: 1.8rem;color: red;">
            <small>{{ queryOptionForm.errors?.includeActiveCasesInvalidMessage }}</small>
          </div>
        </div>

      </div>
      <!-- error messages for top controls. -->
    </div>
    <div class="d-flex flex-row m-1">
      <button class="btn btn-sm primary-button mt-2 mb-1" style="width: 4rem;" type="button"
        [disabled]="!queryOptionForm.valid || isLoading" (click)="executeClick()">
        <span>Execute</span>
      </button>
      <div>
        <button class="btn btn-sm danger-button mt-2 mb-1 ml-1" [disabled]="!isLoading" type="button"
          style="width: 4rem;" (click)="cancelClick()">
          Cancel
        </button>
      </div>
      <button class="btn btn-sm primary-button mt-2 mb-1 ml-3" style="width: 4rem;" type="submit"
        [disabled]="!queryOptionForm.valid || !isExecuteDoneSuccess">
        <span>Save</span>
      </button>
      <button class="btn btn-sm primary-button mt-2 mb-1 ml-3" style="width: 8rem;" type="button"
        [disabled]="!queryOptionForm.valid || !isSaveDoneSuccess || isLoading" (click)="onboardPowerBIClick()">
        <span>Onboard PowerBI</span>
      </button>
      <div *ngIf="isSaveInProgress || isLoading" class="mt-2 mb-1 ml-3">
        <span class="text-primary fa fa-spinner fa-spin fa-2x" aria-label="Processing"></span>
      </div>
    </div>
  </form>
</div>
