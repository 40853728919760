<div class="display">
  <m360_he-page-title> Control Tower </m360_he-page-title>
  <m360_he-text class="subheading" appearance="heading-4">
    Business Scenario:
    <m360_he-text [hidden]="isScenarioLoading">{{ scenario?.scenarioName }}</m360_he-text>
    <m360_he-skeleton class="loading-name" [hidden]="!isScenarioLoading" shape="rect" shimmer></m360_he-skeleton>
  </m360_he-text>
  <m360_he-text class="description" [hidden]="isScenarioLoading">
    {{ scenario?.description }}
  </m360_he-text>
  <div [hidden]="!isScenarioLoading">
    <m360_he-skeleton class="loading-name" shape="rect" shimmer></m360_he-skeleton>
    <m360_he-skeleton class="loading-name" shape="rect" shimmer></m360_he-skeleton>
  </div>
  <m360_he-divider class="divider"></m360_he-divider>

  <!-- data grid for showing scenarios. -->
  <m360_he-data-grid
    #listAllRules
    [columns]="columns"
    [rows]="filteredRows"
    class="business-goal-table"
    [attr.loading]="isBusinessGoalListLoading ? '' : null"
  >
    <div slot="data-grid-controls">
      <m360_he-search-box
        #searchControl
        placeholder="Search"
        class="search-button"
        [attr.readonly]="isBusinessGoalListLoading ? '' : null"
      ></m360_he-search-box>
    </div>
    <!-- generate custom column for relative date time. -->
    <ng-container *ngFor="let relativeTime of filteredRelativeDatetimeRow; let i = index">
      <span [slot]="'lastModifiedOn-' + i">
        <m360_he-relative-time [date]="relativeTime" format="long"></m360_he-relative-time>
      </span>
    </ng-container>

    <!-- generate custom column for relative date time. -->
    <ng-container *ngFor="let activeValue of filteredActiveColumn; let i = index">
      <span [slot]="'active-' + i">
        <form>
          <m360_he-toggle [attr.checked]="activeValue ? '' : null" aria-label="Turn rule on"></m360_he-toggle>
        </form>
      </span>
    </ng-container>
  </m360_he-data-grid>
</div>
