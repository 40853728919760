import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { delay, map } from "rxjs/operators";
import { BusinessGoal, DisplayBusinessGoal } from "../model/business-goal.model";
import { Scenario } from "../model/scenario.model";

@Injectable()
export class BusinessGoalService {
  constructor(private httpClient: HttpClient) {}

  public getScenario(scenarioId: number): Observable<Scenario> {
    const scenarioUrl = "./assets/sample-data/scenario.json";
    return this.httpClient.get<Scenario>(scenarioUrl).pipe(delay(1500));
  }

  public getBusinessGoalList(scenarioId: number): Observable<Array<DisplayBusinessGoal>> {
    const businessGoalUrl = "./assets/sample-data/business-goal.json";
    return this.httpClient.get<Array<BusinessGoal>>(businessGoalUrl).pipe(
      map((businessGoals: Array<BusinessGoal>) => {
        const displayBusinessGoals = new Array<DisplayBusinessGoal>();
        businessGoals.forEach((b) => {
          displayBusinessGoals.push({
            businessGoal: b.businessGoalName,
            lastModifiedOn: b.lastModifiedOn,
            createdBy: b.createdBy,
            active: b.isActive,
          });
        });
        return displayBusinessGoals;
      }),
      delay(2500)
    );
  }
}
