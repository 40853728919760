import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './date.prototypes';
import { APP_CONFIG } from './app/common/constants';
import { AppConfig } from './app/model/app-config.model';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MSAL_GUARD_CONFIG,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { createScope, HarmonyEnablerScope } from '@harmony/enablers/utilities/scope';
import theme from '@harmony/enablers/themes/partner-center';
import { Icon } from '@harmony/enablers/components/icon/icon';
import { Button } from '@harmony/enablers/components/button/button';
import { PageTitle } from '@harmony/enablers/components/page-title/page-title';
import { Divider } from '@harmony/enablers/components/divider/divider';
import { Text } from '@harmony/enablers/components/text/text';
import { TextField } from '@harmony/enablers/components/text-field/text-field';
import { SearchBox } from '@harmony/enablers/components/search-box/search-box';
import { DataGrid } from '@harmony/enablers/components/data-grid/data-grid';
import { Skeleton } from '@harmony/enablers/components/skeleton/skeleton';
import { RelativeTime } from '@harmony/enablers/components/relative-time/relative-time';
import { Toggle } from '@harmony/enablers/components/toggle/toggle';

export const getBaseUrl = () => document.getElementsByTagName('base')[0].href;

if (environment.production) {
  enableProdMode();
}

function MsalInstanceFactory(appConfig: AppConfig): PublicClientApplication {
  return new PublicClientApplication({
    auth: appConfig.msalConfig.auth,
    cache: appConfig.msalConfig.cache,
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string) => {
          if (level == LogLevel.Error) {
            console.log(message);
          }
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

const msalAngularConfigFactory = (appConfig: AppConfig): MsalInterceptorConfiguration =>
  ({
    interactionType: 'redirect',
    protectedResourceMap: new Map(appConfig.msalAngularConfig.protectedResourceMap),
  } as MsalInterceptorConfiguration);

const scope = createScope({
  basePath: getBaseUrl() + 'assets',
  rootElement: document.querySelector('body'),
  prefix: 'm360',
  theme,
  components: [Icon, Button, PageTitle, Divider, DataGrid, SearchBox, Text, TextField, Skeleton, RelativeTime, Toggle],
});

const main = async () => {
  try {
    const data = await fetch('../assets/data/appConfig.json');
    const config = (await data.json()) as AppConfig;
    if (!AppConfig.isValid(config)) {
      alert('Browser has cached version of code. Please refresh the page using Ctrl + F5.');
    }

    const providers = [
      { provide: APP_CONFIG, useValue: config },
      { provide: 'BASE_URL', useFactory: getBaseUrl },
      {
        provide: MSAL_INSTANCE,
        useFactory: MsalInstanceFactory,
        deps: [APP_CONFIG],
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: msalAngularConfigFactory,
        deps: [APP_CONFIG],
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useValue: {
          interactionType: 'redirect',
          authRequest: {
            scopes: ['user.read'],
          },
          loginFailedRoute: '',
        } as MsalGuardConfiguration,
      },
      {
        provide: HarmonyEnablerScope,
        useValue: scope,
      },
    ];

    await platformBrowserDynamic(providers).bootstrapModule(AppModule);
  } catch (err) {
    console.error(err);
  }
};

main();
