export class TeamGroup {
  id: string;
  name: string;
  serviceGroupId: string;
  processList: Array<Process>;
  constructor(id: string, name: string, serviceGroupId: string) {
    this.id = id;
    this.name = name;
    this.serviceGroupId = serviceGroupId;
  }
}

export class Process {
  id: string;
  name: string;
  dataSourceId: number;
  query: string;
  instanceFilters: Array<InstanceFilters>;
  startEvents: string;
  endEvents: string;
  pruneThreshold: number;
  teamGroupId: string;
  processCacheEventLogLastUpdatedTimeUtc: string;
  processCacheEventLogExpirationInMinutes: string;
  processCacheEventlogAutoRefreshInvertalInMinutes: string;
  description: string;
  includeActiveCases: boolean;
}

export class ProcessMiningRequest {
  dataSourceId: number;
  query: string;
  startEvents: Array<string>;
  endEvents: Array<string>;
  pruneThreshold: number;
  includeActiveCases: boolean;
}

export class InstanceFilters {
  id: string;
  displayColumnName: string;
  columnName: string;
  columnType: string;
  columnValue: string;
  queryLanguage: string;
}

export class ServiceGroup {
  id: string;
  name: string;
  organizationId: string;
  constructor(id: string, name: string, organizationId: string) {
    this.id = id;
    this.name = name;
    this.organizationId = organizationId;
  }
}

export class ServiceTreeService {
  id: string;
  name: string;
  teamGroupId: string;
  constructor(id: string, name: string, teamGroupId: string) {
    this.id = id;
    this.name = name;
    this.teamGroupId = teamGroupId;
  }
}

export class Organization {
  id: string;
  name: string;
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class ServiceTreeSelection {
  organization: Organization;
  serviceGroup: ServiceGroup;
  teamGroup: TeamGroup;
  process: Process;
  backTrack: boolean;
}

export class HierarchyTreeNode {
  internalId: string;
  id: string;
  parentId: string;
  nodeId: string;
  nodeType: string;
  nodeName: string;
  constructor(internalId: string, id: string, parentId: string, nodeId: string, nodeType: string, nodeName: string) {
    this.internalId = internalId;
    this.id = id;
    this.parentId = parentId;
    this.nodeId = nodeId;
    this.nodeType = nodeType;
    this.nodeName = nodeName;
  }
}
