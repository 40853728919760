import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { APP_CONFIG } from "./common/constants";
import { Router } from "@angular/router";
import { GraphDataService } from "./service/graph-data.service";
import { DomSanitizer } from "@angular/platform-browser";
import { UserDataService } from "./service/user-data.service";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { AppConfig } from "./model/app-config.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  @ViewChild("mainDiv") mainDiv: ElementRef<HTMLElement>;
  currentAppName: string;
  isCollapsed: boolean;
  userName: string;
  optionsList: Array<string>;
  userImage = "../assets/images/default-avatar.png";

  // Current selected sub link
  currentAppSubOption: string;

  // Event Broker display links.
  eventBrokerOptions = new Array<string>();

  // Monitor Catalog display links.
  monitorCatalogOptions = new Array<string>();

  // BAM Dashboard display links.
  bamDashboardOptions = new Array<string>();

  // Score Card display links
  scoreCardOptions = new Array<string>();

  // Quality Of Service display links.
  qualityOfServiceOptions = new Array<string>();

  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfig,
    private router: Router,
    private graphDataService: GraphDataService,
    private sanitizer: DomSanitizer,
    private userDataService: UserDataService,
    config: NgbModalConfig
  ) {
    this.currentAppName = "Monitoring 360";
    this.isCollapsed = false;
    config["centered"] = true;
  }

  ngOnInit() {
    this.userName = this.userDataService.name;

    // Getting user image from Graph API.
    this.graphDataService.getUserPhoto().subscribe((responseImg) => {
      const url = window.URL;
      const blobUrl = this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(responseImg));
      this.userImage = blobUrl as string;
    });

    // populating options for Event Broker.
    if (this.appConfig.featureFlag.enableEventBroker) {
      this.populateEventBrokerOptions();
    }

    // populating options for Monitoring Catalog.
    if (this.appConfig.featureFlag.enableDashboardCatalog) {
      this.populateMonitoringCatalogOptions();
    }

    // adding options for BAMDashboard to top navbar based on environment file.
    if (this.appConfig.featureFlag.enableBAM) {
      this.populateBAMDashboardOptions();
    }

    // populating options for Score Card.
    if (this.appConfig.featureFlag.enableScoreCard) {
      this.populateScoreCardOptions();
    }

    // populating options for Quality Of Service.
    if (this.appConfig.featureFlag.enableQoS) {
      this.populateQualityOfServiceOptions();
    }

    // populating options for Control Tower
    if (this.appConfig.featureFlag.enableCT) {
      // leaving placeholder to add options.
    }
  }

  collapseNavbar(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public focusMainDiv(): void {
    this.mainDiv.nativeElement.focus();
  }

  navigateMainApp(newOption: string): void {
    // if side nav bar is collapsed and if keyboard enter is pressed on an app,
    // then open nav bar
    if (this.isCollapsed) {
      this.collapseNavbar();
    }

    // update app name on screen.
    this.currentAppName = newOption;
    this.currentAppSubOption = "";

    // navigation constants.
    const homePage = "Monitoring 360";
    const eventBrokerPage = "Event Broker";
    const monitorCatalogPage = "Dashboard Catalog";
    const qosPage = "Quality Of Service";
    const controlTower = "Control Tower";
    const bamDashboardPage = "BAM Dashboard";
    const masterDataManagement = "Master Data Mgmt.";
    const scoreCard = "Score Card";
    const contactUs = "Contact Us";

    // navigate to screen based on selected option.
    switch (newOption) {
      case homePage: {
        // navigate to home page again.
        this.router.navigate([""]);
        break;
      }
      case eventBrokerPage: {
        this.router.navigate(["/event-broker"]);
        break;
      }
      case monitorCatalogPage: {
        // navigate to the browse all catalogs page.
        this.router.navigate(["/monitoring-solution/browse-catalog"]);
        break;
      }
      case qosPage: {
        this.router.navigate(["/quality-of-service"]);
        break;
      }
      case controlTower: {
        this.router.navigate(["/control-tower"]);
        break;
      }
      case bamDashboardPage: {
        this.router.navigate(["/bam-dashboard"]);
        break;
      }
      case masterDataManagement: {
        this.router.navigate(["/master-data"]);
        break;
      }
      case scoreCard: {
        this.router.navigate(["/score-card"]);
        break;
      }
      case contactUs: {
        this.router.navigate(["/contact-us"]);
        break;
      }
      default: {
        console.error("Invalid option selected in navigateMainMenu.", newOption);
      }
    }
  }

  navigateMonitorCatalog(selectedString: string): void {
    // navigation constants for event broker
    const eventCatalogPage = "Event Catalog";
    const eventActivityPage = "Event Activity";

    // navigation constants for monitor catalog.
    const addCatalogPage = "Add New Solution";
    const browseCatalogPage = "Browse All Solutions";

    // navigation constants for bam dashboard
    const bamDashboardHomePage = "Home";
    const bamDashboardAdminPage = "Administration";
    const bamDashboardRuleConfigPage = "Rule Config";
    const bamDashboardProcessFlow = "Process Flow";
    const bamDashboardProcessHealth = "Process Health";
    const bamDashboardProcessDiscovery = "Process Discovery";

    // navigation constants for score card
    const scoreCardScenarioHealth = "Scenario Health";
    const scoreCardCrewHealth = "Crew Health";
    const scoreCardManualMonitoring = "Monitoring Data Entry";
    const monitoringDashboard = "Monitoring Dashboard";

    // navigation constants for Quality Of Service
    const qualityOfServiceAdmin = "QosAdministration";
    const qualityOfServiceDashboard = "QoS View";

    this.currentAppSubOption = selectedString;

    // navigate to the selected page.
    switch (selectedString) {
      case eventCatalogPage: {
        this.router.navigate(["/event-broker/event-catalog"]);
        break;
      }
      case eventActivityPage: {
        this.router.navigate(["/event-broker/event-activity"]);
        break;
      }
      case addCatalogPage: {
        this.router.navigate(["/monitoring-solution/add-dashboard"]);
        break;
      }
      case browseCatalogPage: {
        this.router.navigate(["/monitoring-solution/browse-catalog"]);
        break;
      }
      case bamDashboardAdminPage: {
        this.router.navigate(["/bam-dashboard/administration/process"]);
        break;
      }
      case bamDashboardHomePage: {
        this.router.navigate(["/bam-dashboard"]);
        break;
      }
      case bamDashboardRuleConfigPage: {
        this.router.navigate(["/bam-dashboard/rule/all"]);
        break;
      }
      case bamDashboardProcessFlow: {
        this.router.navigate(["/bam-dashboard/process-flow"]);
        break;
      }
      case bamDashboardProcessHealth: {
        this.router.navigate(["/bam-dashboard/process-health"]);
        break;
      }
      case bamDashboardProcessDiscovery: {
        this.router.navigate(["/bam-dashboard/process-discovery"]);
        break;
      }
      case scoreCardScenarioHealth: {
        this.router.navigate(["/score-card/scenario-health"]);
        break;
      }
      case scoreCardCrewHealth: {
        this.router.navigate(["/score-card/crew-health"]);
        break;
      }
      case scoreCardManualMonitoring: {
        this.router.navigate(["/score-card/manual-monitoring"]);
        break;
      }
      case monitoringDashboard: {
        this.router.navigate(["/score-card/monitoring-dashboard"]);
        break;
      }
      case qualityOfServiceAdmin: {
        this.router.navigate(["/quality-of-service/admin"]);
        break;
      }
      case qualityOfServiceDashboard: {
        this.router.navigate(["/quality-of-service/view"]);
        break;
      }
      default: {
        const scoreCardConfig = this.appConfig.scoreCards.find((config) => config.navLinkName === selectedString);
        if (scoreCardConfig !== undefined) {
          this.router.navigate([scoreCardConfig.route]);
        } else {
          console.error("MonitorCatalog: Invalid Navigation Option");
        }
      }
    }
  }

  private populateEventBrokerOptions() {
    if (this.appConfig.enableEventBroker.enableEventCatalog) {
      this.eventBrokerOptions.push("Event Catalog");
    }
    if (this.appConfig.enableEventBroker.enableEventActivity) {
      this.eventBrokerOptions.push("Event Activity");
    }
  }

  private populateMonitoringCatalogOptions() {
    if (this.appConfig.enableDashboardCatalog.enableAddNewSolution) {
      this.monitorCatalogOptions.push("Add New Solution");
    }
    if (this.appConfig.enableDashboardCatalog.enableBrowseAllSolutions) {
      this.monitorCatalogOptions.push("Browse All Solutions");
    }
  }

  private populateBAMDashboardOptions() {
    if (this.appConfig.enableBAM.enableAdministration) {
      this.bamDashboardOptions.push("Administration");
    }
    if (this.appConfig.enableBAM.enableRuleConfig) {
      this.bamDashboardOptions.push("Rule Config");
    }
    if (this.appConfig.enableBAM.enableProcessFlow) {
      this.bamDashboardOptions.push("Process Flow");
    }
    if (this.appConfig.enableBAM.enableProcessHealth) {
      this.bamDashboardOptions.push("Process Health");
    }
    if (this.appConfig.enableBAM.enableProcessDiscovery) {
      this.bamDashboardOptions.push("Process Discovery");
    }
  }

  private populateScoreCardOptions() {
    if (this.appConfig.enableScoreCard.enableScenarioHealth) {
      this.scoreCardOptions.push("Scenario Health");
    }

    // load up scenario-health-v2 based scorecards from app config
    this.scoreCardOptions.push(
      ...this.appConfig.scoreCards.filter((config) => config.enable).map((config) => config.navLinkName)
    );

    if (this.appConfig.enableScoreCard.enableCrewHealth) {
      this.scoreCardOptions.push("Crew Health");
    }
    if (this.appConfig.enableScoreCard.enableMonitoringDashboard) {
      this.scoreCardOptions.push("Monitoring Dashboard");
    }
  }

  private populateQualityOfServiceOptions() {
    if (this.appConfig.enableQoS.enableAdministration) {
      this.qualityOfServiceOptions.push("QosAdministration");
    }
    if (this.appConfig.enableQoS.enableQoSView) {
      this.qualityOfServiceOptions.push("QoS View");
    }
  }
}
