import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { InjectionToken } from '@angular/core';

export const constants = {

    // Power BI
    ReportEmbedUrl: "https://msit.powerbi.com/reportEmbed?",
    DashboardEmbedUrl: "https://msit.powerbi.com/dashboardEmbed?",
    PowerBIDomainUrl: "https://msit.powerbi.com/",

    // Power BI Input Type
    Dashboard: "dashboard",
    Report: "report",

    // Tree Item Type
    ROOT: "Root",
    SOURCE: "Source",
    DIVISION: "Division",
    ORGANIZATION: "Organization",
    SERVICE_GROUP: "ServiceGroup",
    TEAM_GROUP: "TeamGroup",
    BUSINESS_PROCESS: "BusinessProcess",
};

// to stop closing the modal by clicking backdrop or outside the modal, add options.
export const ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false
  };

  // App config injector
  export const APP_CONFIG = new InjectionToken<any>('Main Application Configuration');
